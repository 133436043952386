import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DataTable from "react-data-table-component";
import { formatDate } from '../../../helpers/formatDate';
import { handleSaleReportData } from '../../../services/reportService';
import { showToastMessage } from '../../../helpers/toastr';
import * as XLSX from 'xlsx';
import { useReactToPrint } from 'react-to-print';
import { mdiFileDownload, mdiFileExcel, mdiFileExcelBox } from '@mdi/js';  // Excel icon
import { mdiFilePdfBox } from '@mdi/js';
import Icon from '@mdi/react';
import { handleSettingData } from '../../../services/userProfile';
import { paymentOption } from '../../../helpers/paymentOption';

const SalesReport = ({ selectGlobalOrg }) => {
  const userData = useSelector((state) => state.userData);

  const [selectedDay, setSelectedDay] = useState('');
  const [selectedWeek, setSelectedWeek] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selection, setSelection] = useState('Today');
  const [dateInputValue, setDateInputValue] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [data, setData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const handleSelectionChange = (e) => {
    setSelection(e.target.value);
    clearDateInputs(); // Clear date inputs when selection changes
  };

  const handleDateInputChange = (e) => {
    setDateInputValue(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const clearDateInputs = () => {
    setDateInputValue('');
    setStartDate('');
    setEndDate('');
  };
  const handleMonthChange = (e) => {
    if (selection === "Month") {
      setMonth(e.target.value);
    }
  };

  const handleYearChange = (e) => {
    if (selection === "Month") {
      setYear(e.target.value);
    }
  };

  useEffect(() => {
    if (selection === "Month" && (!month || !year)) {
      return;
    }
    fetchData();
  }, [selectGlobalOrg, selection, dateInputValue, startDate, endDate, month, year]);

  const fetchData = async () => {
    const orgID = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
    let filterType = selection;
    let filterPeriod = "";

    if (selection === "Date") {
      filterPeriod = dateInputValue;
    }

    const formData = {
      orgID,
      filterType,
      month: selection === "Month" ? month : "",
      year: selection === "Month" ? year : "",
      filterPeriod, // For other selections like "Date"
      startDate: selection === "CustomDate" ? startDate : "",
      endDate: selection === "CustomDate" ? endDate : "",
    };


    try {
      const result = await handleSaleReportData(formData);
      if (result.data) {
        const reportData = result.data.data;
        setData(reportData);
        setTotalAmount(reportData?.totalAmount);
      }
    } catch (error) {
      console.error('Error fetching sales report data:', error);
      showToastMessage('error', error.response?.data?.message || 'Error fetching data');
    }
  };



  const calculateTotal = (reportData) => {
    // Flatten all rows from different data groups into a single array
    const allRows = [
      ...(reportData?.serviceBillings || []),
      ...(reportData?.memberships || []),
      ...(reportData?.productBillings || []),
      ...(reportData?.advancePackages || []),
    ];

    // Calculate the total from the combined array
    const total = allRows.reduce((acc, row) => {
      const amount = row?.calculated_percentage ?? row?.round_off_total ?? 0;
      return acc + amount;
    }, 0);
    const roundedTotal = Math.round(total);

    // Alternatively, to round to 2 decimal places, use:
    // const roundedTotal = parseFloat(total.toFixed(2));

    setTotalAmount(roundedTotal);
  };

  const columns = [
    {
      name: 'Bill No',
      selector: (row) => row.bill_no ?? row?.id ?? "Null",
      sortable: true,
    },
    {
      name: 'Customer Name',
      selector: row => row.get_customer_info?.name ?? row?.customer?.name,
      sortable: true,
    },
    {
      name: 'Mobile Number',
      selector: row => row.get_customer_info?.phone_number ?? row?.customer?.phone_number,
      sortable: true,
    },
    {
      name: 'Service Type',
      selector: row => row.get_service_billings?.[0]?.payment_method === 'Package'
        ? 'Package Base'
        : 'Service Base' ?? 'N/A',
      sortable: true,
    },
    {
      name: 'Payment Method',
      selector: row => paymentOption(row?.payment_mode),
      sortable: true,
    },
    {
      name: 'Discount',
      selector: row => row.discount ?? 0,
      sortable: true,
    },
    {
      name: 'Total',
      selector: (row) => {
        return row?.calculated_percentage ?? row.round_off_total ?? row?.total_amount ?? 0;
      },
      sortable: true,
    },
    {
      name: 'Created',
      selector: row => row.created_at ? formatDate(row.created_at) : '',
      sortable: true,
    },
  ];
  const advancePackageColumns = [
    {
      name: 'Sr No',
      selector: (row) => row.bill_no ?? row?.id ?? "Null",
      sortable: true,
    },
    {
      name: 'Customer Name',
      selector: row => row.get_customer_info?.name ?? row?.customer?.name,
      sortable: true,
    },
    {
      name: 'Mobile Number',
      selector: row => row.get_customer_info?.phone_number ?? row?.customer?.phone_number,
      sortable: true,
    },
    {
      name: 'Payment Method',
      selector: row => paymentOption(row?.payment_mode),
      sortable: true,
    },
    {
      name: 'Discount',
      selector: row => row.discount ?? 0,
      sortable: true,
    },
    {
      name: 'Total',
      selector: (row) => {
        return row?.calculated_percentage ?? row.round_off_total ?? row?.total_amount ?? 0;
      },
      sortable: true,
    },
    {
      name: 'Created',
      selector: row => row.created_at ? formatDate(row.created_at) : '',
      sortable: true,
    },
  ];

  useEffect(() => {
    fetchData();
  }, [selectGlobalOrg, selectedDay, selectedWeek, selectedDate, selectedMonth]);

  useEffect(() => {
    if (data.length > 0) {
      calculateTotal(data); // Call calculateTotal once the data is available
    }
  }, [data]);

  const exportToExcel = () => {
    const visibleColumns = columns.filter((col) => col.selector);

    // Combine all rows from the data object into a single array
    const allRows = [
      ...(data?.serviceBillings || []),
      ...(data?.memberships || []),
      ...(data?.productBillings || []),
      ...(data?.advancePackages || []),
    ];
    const filteredData = allRows?.map(row => {
      const filteredRow = {};
      visibleColumns.forEach(col => {
        filteredRow[col.name] = col.selector(row);
      });
      return filteredRow;
    });

    // Add total row
    const totalRow = {
      'Bill No': 'Total',
      'Customer Name': '',
      'Mobile Number': '',
      'Service Type': '',
      'Payment Method': '',
      'Discount': '',
      'Total': totalAmount,  // Use the totalAmount from state
      'Created': '',
    };
    filteredData.push(totalRow);

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sales Report');
    XLSX.writeFile(wb, 'sales_report.xlsx');
  };

  const handlePrint = useReactToPrint({
    content: () => {
      const printTable = document.getElementById('print-table');
      const footerRow = document.createElement('div');
      footerRow.textContent = `Total for Page: ${calculatePageTotal(data)}`;
      footerRow.style = 'text-align: right; font-weight: bold; margin-top: 10px;';
      printTable.appendChild(footerRow);
      return printTable;
    },
    documentTitle: 'Sales Report',
    onAfterPrint: () => showToastMessage('success', 'PDF Exported successfully'),
  });

  const calculatePageTotal = (rows) => {
    // Check if rows is an array and contains data
    if (!Array.isArray(rows) || rows.length === 0) {
      console.error("Invalid data passed to calculatePageTotal:", rows);
      return 0; // Return 0 for invalid or empty data
    }

    // Calculate the total by reducing the rows array
    return rows.reduce((acc, row) => {
      const amount = row?.calculated_percentage ?? row?.round_off_total ?? 0;
      return acc + amount;
    }, 0);
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header bg-transparent">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-title heading-h3 mb-0">Sales Reports</h4>
              </div>
              <div class="col-auto">
                <div class="card-filter-input">
                  <>
                    <button
                      onClick={exportToExcel}
                      className="btn btn-block btn-h-auto btn-gradient-danger btn-lg font-weight-medium auth-form-btn"
                    >
                      Export Excel
                    </button>
                    <button
                      onClick={handlePrint}
                      className="btn btn-block btn-h-auto btn-gradient-danger btn-lg font-weight-medium auth-form-btn"
                    >
                      Download PDF
                    </button>
                    <span className="btn btn-block btn-plane btn-h-auto">
                      <strong>Total: </strong>{totalAmount}
                    </span>
                  </>
                  <div className="input-date">
                    <select
                      className="form-select"
                      value={selection}
                      onChange={handleSelectionChange}
                    >
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Date">Date</option>
                      <option value="Last-7-Days">Last 7 Days</option>
                      <option value="Last-14-Days">Last 14 Days</option>
                      <option value="Last-30-Days">Last 30 Days</option>
                      <option value="Month">Month</option>
                      <option value="Overall">Overall</option>
                      <option value="CustomDate">Custom Date</option>
                    </select>
                  </div>
                  {selection === "CustomDate" ? (
                    <>
                      <div className="input-date">
                        <input
                          type="date"
                          value={startDate}
                          onChange={handleStartDateChange}
                          className="form-control"
                        />
                      </div>

                      <div className="input-date">
                        <input
                          type="date"
                          value={endDate}
                          onChange={handleEndDateChange}
                          className="form-control"
                        />
                      </div>
                    </>
                  ) : selection === "Date" ? (
                    <div className="input-date">
                      <input
                        type="date"
                        value={dateInputValue}
                        onChange={handleDateInputChange}
                        className="form-control"
                      />
                    </div>
                  ) : selection === "Month" ? (
                    <>
                      <div className="input-date">
                        <select
                          value={month}
                          onChange={handleMonthChange}
                          className="form-select"
                        >
                          <option value="">Select Month</option>
                          <option value="01">January</option>
                          <option value="02">February</option>
                          <option value="03">March</option>
                          <option value="04">April</option>
                          <option value="05">May</option>
                          <option value="06">June</option>
                          <option value="07">July</option>
                          <option value="08">August</option>
                          <option value="09">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </select>
                      </div>
                      <div className="input-date">
                        <select
                          value={year}
                          onChange={handleYearChange}

                          className="form-select"
                        >
                          <option value="">Select Year</option>
                          {Array.from({ length: 71 }, (_, index) => 2023 + index).map((yearValue) => (
                            <option key={yearValue} value={yearValue}>
                              {yearValue}
                            </option>
                          ))}
                        </select>

                      </div>
                    </>
                  ) :
                    null}
                </div>

              </div>
            </div>
          </div>
          <div className="card-body">
            <div id="print-table">
              {/* Service Billing Table */}
              <h4>Service Billings</h4>
              <DataTable
                columns={columns}
                data={data?.serviceBillings || []}
                pagination
                responsive
                striped
                highlightOnHover
              />
              {/* Membership Table */}
              <h4>Memberships</h4>
              <DataTable
                columns={columns}
                data={data?.memberships || []}
                pagination
                responsive
                striped
                highlightOnHover
              />
              {/* Product Billing Table */}
              <h4>Product Billings</h4>
              <DataTable
                columns={columns}
                data={data?.productBillings || []}
                pagination
                responsive
                striped
                highlightOnHover
              />
              {/* Advance Package Table */}
              <h4>Advance Packages</h4>
              <DataTable
                columns={columns}
                data={data?.advancePackages || []}
                pagination
                responsive
                striped
                highlightOnHover
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesReport;
